<template>
  <q-dialog :ref="$options.name" persistent>
    <q-card>
      <q-card-section>
        <div class="text-h6" v-html="header" />
      </q-card-section>

      <q-card-section class="q-pt-none" v-html="message" />

      <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
        <q-btn
          flat
          @click="handleCancelClick"
          label="Cancel"
          color="subtle"
          v-close-popup
          :disable="submitting"
        />
        <q-btn
          @click="handleOkClick"
          :label="buttonLabel"
          color="primary"
          :disable="submitting"
          :loading="submitting"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    header: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      required: true,
      default: "Confirm"
    }
  },
  emits: ["ok", "hide"],
  data() {
    return {
      submitting: false
    };
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    },
    handleOkClick() {
      this.$emit("ok", this);
      // this.hide();
    },
    handleCancelClick() {
      this.$emit("hide", this);
      // this.hide();
    }
  }
};
</script>

<style scoped lang="scss"></style>
