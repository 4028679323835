<template>
  <div>
    <PageDynamicBreadcrumb />
    <PageHeader title="Businesses Overview" />

    <DataTable
      table-key="businessesOverview"
      row-key="id"
      title="Businesses"
      :columns="columns"
      :rows="businesses"
      :loading="loading"
      :pagination="{
        sortBy: 'time_created',
        descending: true,
        page: 1,
        rowsPerPage: 10
      }"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:primary_admin="row">
        <span v-if="!('id' in row.admin_user)">
          -
        </span>
        <div v-else>
          {{ row.admin_user.first_name }} {{ row.admin_user.last_name }}
          <p class="text-subtle">
            {{ row.admin_user.email }}
          </p>
        </div>
      </template>
      <template v-slot:actions="row">
        <ActionsDropdownButton :actions="getBusinessActionList(row)" />
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";
import PageHeader from "@/components/UI/PageHeader.vue";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb.vue";
import DataTable from "@/components/DataTable.vue";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton.vue";
import axios from "axios";
import {Dialog} from "quasar";
import ConfirmationModal from "@/components/UI/ConfirmationModal.vue";
moment.locale("en");

export default {
  name: "BusinessesOverview",
  components: {
    ActionsDropdownButton,
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      networkAxios: null,
      loading: true,
      filter: "",
      businesses: [],
      columns: [
        {
          name: "id",
          label: "ID",
          field: "id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "name",
          label: "Name",
          field: "name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "type",
          label: "Type",
          field: row => this.capitalizeText(row.type.replaceAll("_", " ")),
          sortable: true,
          type: "dimension"
        },
        {
          name: "timezone",
          label: "Timezone",
          field: "timezone",
          sortable: true,
          type: "dimension"
        },
        {
          name: "time_created",
          label: "Date Partnered",
          field: row =>
              row.time_created ? moment.utc(row.time_created).local() : "",
          format: val => (!val ? "-" : moment(val).format("ll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          align: "left"
        },
        {
          name: "primary_admin",
          label: "Primary Admin",
          field: row => ('id' in row.admin_user ? row.admin_user.first_name + " " + row.admin_user.last_name : "-"),
          hideField: true,
          sortable: true,
          align: "left"
        },
        {
          name: "primary_admin_email",
          label: "Primary Admin Email",
          field: row => ('email' in row.admin_user ? row.admin_user.email : "-"),
          visible: false,
          hideField: true,
          sortable: false
        },
        {
          name: "advertiser_accounts",
          label: "Advertiser Accounts",
          field: "advertiser_accounts",
          format: val => (val == 0 ? "-" : val),
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number",
          align: "left"
        },
        {
          name: "inventory_sources",
          label: "Inventory Sources",
          field: "inventory_sources",
          format: val => (val == 0 ? "-" : val),
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number",
          align: "left"
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false,
          sortable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {},
  mounted() {
    Promise.all([this.fetchBusinesses(), this.initNetworkAxios()])
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    fetchBusinesses() {
      return new Promise((resolve, reject) => {
        axios({
          url: "https://api-v2.jumpfeed.com/internal/businesses"
        })
          .then(resp => {
            this.businesses = resp.data.data;
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    },
    initNetworkAxios() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "https://api-v2.jumpfeed.com/network/app/auth",
            {
              email: "no-reply@jumpfeed.com",
              password: "jumpfeed"
            }
          )
          .then(resp => {
            let networkToken = resp.data.data.token;
            if (networkToken) {
              this.networkAxios = axios.create({
                headers: {
                  Authorization: 'Bearer ' + networkToken
                }
              });
            }
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    },
    getBusinessActionList(business) {
      let actions = [];

      if ('id' in business.admin_user && !business.admin_user.is_verified) {
        actions.push({
          action: () => this.promptSendNetworkVerificationEmail(business.admin_user.email, business.name),
          label: "Send Verification Email"
        });
      }

      return actions;
    },
    promptSendNetworkVerificationEmail(email, businessName) {
      Dialog.create({
        component: ConfirmationModal,
        componentProps: {
          header: "Send Verification Email",
          message:
            "You are about to send the CarScience verification email to <b>" + email + "</b> (the primary admin for <b>" + businessName + "</b>). Are you sure you want to continue?"
        }
      })
        .onOk(dialog => {
          dialog.submitting = true;
          axios
            .post(
              "https://api-v2.jumpfeed.com/network/app/users/send-user-verification-email",
              { email: email }
            )
            .then(() => {
              this.triggerActionOutcomeAlert("send", true);
            })
            .catch(err => {
              console.log(err);
              this.triggerActionOutcomeAlert("send", false);
            })
            .finally(() => {
              dialog.submitting = false;
              dialog.hide();
            });
        })
        .onCancel(dialog => {
          dialog.hide();
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
